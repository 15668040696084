import { render, staticRenderFns } from "./VersionMessagesList.vue?vue&type=template&id=453f1459&scoped=true"
import script from "./VersionMessagesList.vue?vue&type=script&lang=js"
export * from "./VersionMessagesList.vue?vue&type=script&lang=js"
import style0 from "./VersionMessagesList.vue?vue&type=style&index=0&id=453f1459&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453f1459",
  null
  
)

export default component.exports
<template>
  <v-dialog v-model="dialog" persistent max-width="1000px">
    <v-card data-testid="container-version-message">
      <v-toolbar extended>
        <span class="headline" v-html="getTitle()"></span>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs v-model="activeActionTab" fixed-tabs>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab
              :class="[checkValidationFirstStep() ? 'error-tab-title' : '']"
              data-testid="tab-main-settings"
            >
              <v-icon left>mdi-settings-box</v-icon>
              Main settings
            </v-tab>
            <v-tab
              :class="[
                errors.has('form-version-message.engine_keys')
                  ? 'error-tab-title'
                  : '',
              ]"
              data-testid="tab-engine-settings"
            >
              <v-icon left>mdi-monitor-multiple</v-icon>
              Engines settings
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-form
          data-vv-scope="form-version-message"
          data-testid="form-version-message"
        >
          <v-tabs-items v-model="activeActionTab" touchless>
            <v-tab-item :transition="false" :reverse-transition="false">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-switch
                      v-model="activeItem.is_active"
                      label="Active"
                      input-value="true"
                      data-vv-validate-on="change"
                      data-testid="switch-version-message-is-active"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-switch
                      v-model="activeItem.forced"
                      label="Forced"
                      input-value="true"
                      data-vv-validate-on="change"
                      data-testid="switch-version-message-forced"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="Title"
                      v-model="activeItem.title"
                      :error-messages="
                        errors.collect('form-version-message.title')
                      "
                      v-validate="'required'"
                      data-vv-name="title"
                      data-vv-validate-on="change"
                      data-testid="title"
                    />
                    <v-textarea
                      outlined
                      label="Message"
                      v-model="activeItem.message"
                      :error-messages="
                        errors.collect('form-version-message.message')
                      "
                      data-vv-name="message"
                      data-testid="message"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      label="Version"
                      v-model="activeItem.version"
                      :error-messages="
                        errors.collect('form-version-message.version')
                      "
                      v-validate="{
                        required: true,
                        regex: '^[0-9]+(\\.[0-9]+)+',
                      }"
                      data-vv-name="version"
                      data-vv-validate-on="change"
                      class="ml-2"
                      data-testid="version"
                    />
                    <v-text-field
                      outlined
                      label="Download link"
                      v-model="activeItem.link"
                      :error-messages="
                        errors.collect('form-version-message.link')
                      "
                      v-validate="{
                        required: true,
                        regex:
                          'http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+',
                      }"
                      data-vv-name="link"
                      data-vv-validate-on="change"
                      class="ml-2"
                      data-testid="link"
                    />
                    <v-text-field
                      outlined
                      label="App"
                      v-model="activeItem.app"
                      :error-messages="
                        errors.collect('form-version-message.app')
                      "
                      v-validate="'required'"
                      data-vv-name="app"
                      data-vv-validate-on="change"
                      class="ml-2"
                      data-testid="app"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
              <v-alert
                :value="true"
                type="error"
                v-if="errors.has('form-version-message.engine_keys')"
                data-testid="engine-keys-errors"
              >
                {{ errors.first("form-version-message.engine_keys") }}
              </v-alert>
              <v-data-table
                v-model="selectedEngines"
                :headers="headers"
                :items="engines"
                class="elevation-0 border"
                :search="search"
                :loading="showTableLoader"
                :loading-text="
                  showTableLoader ? 'Loading…' : 'No data available'
                "
                :options.sync="options"
                item-key="_key"
                :single-select="false"
                show-select
                :page.sync="options.page"
                :items-per-page.sync="options.itemsPerPage"
                :server-items-length="enginesCompanyListCount"
                :footer-props="footerOptions"
                sortable
                :multi-sort="false"
                @pagination="onPagination"
                data-testid="table-engines"
              >
                <template v-slot:top>
                  <v-row class="pa-3">
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        :style="
                          $vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''
                        "
                        data-testid="filter-search"
                      />
                    </v-col>
                    <v-col cols="12" md="6" v-if="$can('read', 'Superadmin')">
                      <CompanyFilter
                        :hideDetails="true"
                        v-on:update:selected="onCompanyFilterChange($event)"
                        data-testid="filter-company"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div style="width: 120px" data-testid="engine-name">
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.serial_number`]="{ item }">
                  <div style="width: 120px" data-testid="engine-serial-number">
                    {{ item.serial_number }}
                  </div>
                </template>
                <template v-slot:[`item.app_version`]="{ item }">
                  <span data-testid="engine-app-version">
                    {{ getEngineAppVersion(item) }}
                  </span>
                </template>
                <template v-slot:[`item.last_user`]="{ item }">
                  <span data-testid="engine-last-user">
                    {{ getFullname(item.user, true) }}
                  </span>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    v-if="$can('read', 'Superadmin') || $can('read', 'engines')"
                    data-testid="engine-company"
                  >
                    {{ item.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.last_login`]="{ item }">
                  <div style="width: 120px" data-testid="engine-last-logined">
                    {{ getLastLogined(item) }}
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
        <!--<v-btn flat @click.native="stepper = 2">Back</v-btn>-->
        <v-btn
          text
          v-if="checkShowSaveBtn()"
          color="success"
          @click.stop="save()"
          data-testid="btn-save"
        >
          Save
        </v-btn>
        <v-btn
          text
          v-if="checkShowNextBtn()"
          color="primary"
          @click="activeActionTab = 1"
          data-testid="btn-next"
        >
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
    <Confirm ref="confirmRemovePresetsIntersections"></Confirm>
  </v-dialog>
</template>

<script>
import moment from "moment"
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general"
import Confirm from "@/components/Confirm"
import CompanyFilter from "@/components/filters/CompanyFilter.vue"
import _ from "lodash"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      },
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    gotoStepTab: {
      type: Number,
      default: null,
    },
  },
  mixins: [GeneralMixin],
  components: {
    Confirm,
    CompanyFilter,
  },
  data() {
    return {
      selectedEnginesKeys: [],
      search: "",
      loading: false,
      options: {
        sortBy: [
          "name",
          "serial_number",
          "app_version",
          "companies",
          "last_login",
        ],
        page: 1,
        itemsPerPage: 10,
        group_key: null,
      },
      headers: [
        {
          text: "",
          value: "",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Serial number",
          value: "serial_number",
        },
        {
          text: "App version",
          value: "app_version",
        },
        {
          text: "Company",
          value: "company_name",
        },
        {
          text: "Last User",
          value: "last_user",
          sortable: false,
        },
        {
          text: "Last Login",
          value: "last_login",
        },
      ],
      activeActionTab: null,
      filters: {
        app_version: [],
      },
      footerOptions: {
        "items-per-page-options": [5, 10, 15, 20, 30],
      },
    }
  },

  watch: {
    dialog: function (newVal, oldVal) {
      let self = this
      if (newVal) {
        self.getEnginesList()
        this.activeActionTab = this.gotoStepTab
        this.selectedEnginesKeys = this.activeItem.engine_keys || []
      }
    },
    options: {
      handler(val) {
        this.getEnginesList()
      },
      deep: true,
    },
    search: function () {
      this.getEnginesList()
    },
    enginesCompanyListCount: function (val) {
      if (val < this.options.page * this.options.itemsPerPage) {
        this.options.page = 1
        this.getEnginesList()
      }
    },
  },

  created: function () {
    this.getEnginesList()
  },

  methods: {
    onCompanyFilterChange(event) {
      this.options.group_key = event
    },

    getEngineAppVersion(item) {
      return item.app_version ? _.values(item.app_version)[0] : "" //_.keys(item.app_version)[0] + " " +
    },

    getLastLogined(item) {
      return item.last_login
        ? moment.unix(item.last_login).format("MM/DD/YYYY HH:mm")
        : ""
    },

    checkValidationFirstStep() {
      return (
        this.errors.has("form-version-message.title") ||
        this.errors.has("form-version-message.message") ||
        this.errors.has("form-version-message.link") ||
        this.errors.has("form-version-message.app") ||
        this.errors.has("form-version-message.version")
      )
    },

    checkShowSaveBtn() {
      return true
      // return this.activeActionTab === 1 && this.selectedEnginesKeys.length !== 0
    },

    checkShowNextBtn() {
      return this.activeActionTab === 0 && this.activeItem.title !== ""
    },

    getEnginesList: function () {
      let self = this
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let getData = {
        params: {
          show_active_preset: 1,
          p: page || 1,
          pp: itemsPerPage,
          // keep: "_key,app_version,company_key,company_name,last_login,user,name,serial_number,_active_preset,description,is_online",
        },
      }
      if (self.search !== "") {
        getData.params.search = self.search
      }
      if (sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0) {
        getData.params.sort = _.get(sortBy, "[0]")
        getData.params.order = _.get(sortDesc, "[0]") ? "DESC" : "ASC"
      }
      if (self.options.group_key !== "")
        getData.params.company_key = self.options.group_key
      self.$store.dispatch("enginesCompanyGetList", getData)
    },

    getTitle() {
      return this.editedIndex == -1
        ? "Add new version message"
        : `<span class="blue-grey--text text--lighten-4">Edit version message</span> ${this.activeItem.title}`
    },

    createVersionMessage(data) {
      let self = this
      self.$validator.errors.clear("form-version-message")
      self.$validator.validateAll("form-version-message").then((result) => {
        if (result) {
          self.$store
            .dispatch("versionMsgsCreateAction", data)
            .then(({ data }) => {
              self.$emit("data-saved")
              self.closeDialog()
              let params = {
                text:
                  "Version message " +
                  self.activeItem.title +
                  " was created successfully.",
                show: true,
              }
              self.$root.$emit("snackbar", params)
            })
            .catch((error) => {
              self.handleValidationErrors(
                error.response,
                "form-version-message"
              )
            })
        }
      })
    },

    editVersionMessage(data) {
      let self = this
      self.$validator.errors.clear("form-version-message")
      self.$validator.validateAll("form-version-message").then((result) => {
        if (result) {
          self.$store
            .dispatch("versionMsgsEditAction", data)
            .then(({ data }) => {
              self.$emit("data-saved")
              self.closeDialog()
              let params = {
                text:
                  "Version message " +
                  self.activeItem.title +
                  " was edited successfully.",
                show: true,
              }
              self.$root.$emit("snackbar", params)
            })
            .catch((error) => {
              console.log("error on editVersionMessage", error.response)
              self.handleValidationErrors(
                error.response,
                "form-version-message"
              )
            })
        }
      })
    },

    save() {
      let self = this
      let formData = {
        data: {
          title: self.activeItem.title,
          message: self.activeItem.message,
          app: self.activeItem.app,
          link: self.activeItem.link,
          version: self.activeItem.version,
          forced: self.activeItem.forced,
          is_active: self.activeItem.is_active,
          engine_keys: this.selectedEnginesKeys,
        },
      }
      if (this.editedIndex > -1) {
        formData["slug"] = this.editedIndex
        self.editVersionMessage(formData)
      } else {
        self.createVersionMessage(formData)
      }
    },

    deleteVersionMessage(item) {
      let self = this
      self.$store.dispatch("saveTableLoaderAction", true)
      self.$store.dispatch("versionMsgsDeleteAction", item).then(({ data }) => {
        self.$emit("data-saved")
        self.$store.dispatch("saveTableLoaderAction", false)
      })
    },

    closeDialog() {
      this.$emit("update:dialog", false)
      this.$validator.errors.clear("form-version-message")
    },

    toggleAll() {
      if (this.selectedEnginesKeys.length) this.selectedEnginesKeys = []
      else this.selectedEnginesKeys = this.engines.slice()
    },

    onPagination() {
      //this.selectedEnginesKeys = this.activeItem.engine_keys || []
    },
  },
  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      engines: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
    }),
    selectedEngines: {
      get() {
        return this.engines
          ? this.engines.filter((e) =>
              this.selectedEnginesKeys.includes(e._key)
            )
          : []
      },
      set(val) {
        let pageSelectedEnginesKeys = val.map((e) => e._key)
        let pageAllEnginesKey = this.engines.map((e) => e._key)
        let filteredSelectedEnginesKeys = this.selectedEnginesKeys.filter(
          (se) => pageAllEnginesKey.includes(se)
        )
        let addKeyArr = _.difference(
          pageSelectedEnginesKeys,
          filteredSelectedEnginesKeys
        )
        let removeKeyArr = _.difference(
          filteredSelectedEnginesKeys,
          pageSelectedEnginesKeys
        )
        addKeyArr.forEach((key) => {
          this.selectedEnginesKeys.push(key)
        })
        removeKeyArr.forEach((key) => {
          let index = this.selectedEnginesKeys.indexOf(key)
          if (index !== -1) {
            this.selectedEnginesKeys.splice(index, 1)
          }
        })
      },
    },
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Version messages</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          @click.stop="createVersionMessage()"
          v-if="$can('create', 'app_version_message')"
          data-testid="btn-create-version-message"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <VersionMessagesDialogCrud
          :dialog.sync="dialog"
          :editedIndex="editedIndex"
          :activeItem="activeItem"
          :gotoStepTab="gotoStepTab"
          @data-saved="fetchTableData"
          ref="refDialogVersionMsg"
        ></VersionMessagesDialogCrud>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="table"
          :calculate-widths="true"
          :headers="headers"
          :items="versionMessages"
          :server-items-length="versionMessagesCount"
          sortable
          class="elevation-0 border"
          :loading="showTableLoader"
          :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-version-messages"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="px-3 pt-3">
              <v-col cols="12" md="6">
                <v-text-field
                  ref="textField"
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <div data-testid="version-message-title">
              {{ item.title }}
            </div>
          </template>
          <template v-slot:[`item.version`]="{ item }">
            <div data-testid="version-message-version">
              {{ item.version }}
            </div>
          </template>
          <template
            v-slot:[`item.forced`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <span
              :class="
                item.forced
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
              data-testid="version-message-forced"
            >
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular">{{
                item.forced ? "Yes" : "No"
              }}</span>
            </span>
          </template>
          <template v-slot:[`item.keys`]="{ item }">
            <div data-testid="version-message-received">
              {{ itemEngineKeysLength(item) }}
            </div>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span
              :class="
                item.is_active
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
              data-testid="version-message-status"
            >
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular">{{
                item.is_active ? "Active" : "Inactive"
              }}</span>
            </span>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu
              transition="slide-y-transition"
              left
              v-if="
                $can('delete', 'app_version_message') ||
                $can('update', 'app_version_message')
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                  data-testid="btn-version-message-actions-more"
                  v-if="
                    item.engine_keys &&
                    ($can('update', 'app_version_message') ||
                      $can('delete', 'app_version_message'))
                  "
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  @click="editVersionMessage(item, 1)"
                  v-if="
                    item.engine_keys && $can('update', 'app_version_message')
                  "
                  data-testid="btn-edit-engines-version-message"
                >
                  <v-list-item-content>
                    <v-list-item-title>Engine list</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit"
                      >desktop_windows</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="editVersionMessage(item, 0)"
                  v-if="
                    item.engine_keys && $can('update', 'app_version_message')
                  "
                  data-testid="btn-edit-version-message"
                >
                  <v-list-item-content>
                    <v-list-item-title>Edit message</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit">
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="deleteVersionMessage(item)"
                  v-if="
                    item.engine_keys && $can('delete', 'app_version_message')
                  "
                  data-testid="btn-delete-version-message"
                >
                  <v-list-item-content>
                    <v-list-item-title>Delete message</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('delete', 'app_version_message') ||
                $can('update', 'app_version_message') ||
                $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="editVersionMessage(item, 1)"
                  v-if="
                    item.engine_keys && $can('update', 'app_version_message')
                  "
                  icon="desktop_windows"
                  text="Engine list"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-engines-version-message"
                />
                <VerticalButton
                  @click.native="editVersionMessage(item, 0)"
                  v-if="
                    item.engine_keys && $can('update', 'app_version_message')
                  "
                  icon="mdi-pencil"
                  text="Edit message"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-version-message"
                />
                <VerticalButton
                  @click.native="deleteVersionMessage(item)"
                  v-if="
                    item.engine_keys && $can('delete', 'app_version_message')
                  "
                  icon="mdi-delete"
                  text="Delete message"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-version-message"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
    <Confirm ref="confirmDeletePreset"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { cloneDeep } from "lodash"
import VersionMessagesDialogCrud from "./VersionMessagesDialogCrud"
import Confirm from "@/components/Confirm"
import BackButtonMixin from "@/mixins/close-modal-on-backbutton.js"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    VersionMessagesDialogCrud,
    Confirm,
    VerticalButton,
    DataTableExtended,
    SortMobile,
  },

  mixins: [BackButtonMixin],

  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Title",
          align: "left",
          value: "title",
          sortable: true,
        },
        {
          text: "App version",
          align: "left",
          value: "version",
        },
        {
          text: "Forced",
          align: "center",
          value: "forced",
          sortable: true,
        },
        {
          text: "Received",
          align: "center",
          value: "keys",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "is_active",
          sortable: true,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 50,
        },
      ],
      versionMessages: [],
      versionMessagesCount: 0,
      editedIndex: -1,
      activeItem: {
        title: "",
        message: "",
        app: "",
        link: "",
        version: "",
        forced: false,
        is_active: true,
      },
      defaultItem: {
        title: "",
        message: "",
        app: "",
        link: "",
        version: "",
        forced: false,
        is_active: true,
      },
      loading: false,
      gotoStepTab: null,
      showTableLoader: false, // @todo save muiltiple loader for different tables
      filterSearch: "",
    }
  },

  mounted: function () {
    if (!this.$can("read", "app_version_message")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {
          // catch router push errors
        })
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    filterSearch() {
      this.fetchTableData()
    },
  },

  methods: {
    itemEngineKeysLength(item) {
      return item.engine_keys.length
    },
    fetchTableData: function () {
      let self = this
      let getParams = self.$refs.table.getTableServerParams()
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      self.showTableLoader = true
      self.$store
        .dispatch("versionMsgsGetLists", { params: getParams })
        .then(function (response) {
          self.versionMessages = response?.data?.data?.messages || []
          self.versionMessagesCount = response?.data?.data?.full_count || 0
          self.showTableLoader = false
        })
    },
    createVersionMessage() {
      this.dialog = true
      this.activeItem = cloneDeep(this.defaultItem)
      this.editedIndex = -1
    },
    editVersionMessage(item, gotoStepTab) {
      this.dialog = true
      this.activeItem = cloneDeep(item)
      this.gotoStepTab = gotoStepTab
      this.editedIndex = item._key
    },
    deleteVersionMessage(item) {
      let self = this
      self.$refs.confirmDeletePreset
        .open(
          "Delete",
          "Are you sure you want to delete this version message?",
          { color: "red darken-4" }
        )
        .then((confirm) => {
          if (confirm) {
            self.$refs.refDialogVersionMsg.deleteVersionMessage(item)
          }
        })
    },
    close() {
      this.dialog = false
      this.editedIndex = -1
    },
  },
  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
    }),
    getCompleteUrl(item) {
      return (item) => {
        return item.protocol + "://" + item.hostname + ":" + item.port
      }
    },
    headersComputed() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.headers
      } else {
        let sorted = this.headers.map((h) => {
          if (
            h.value !== "engine_list" &&
            h.value !== "edit" &&
            h.value !== "delete"
          )
            return h
        })
        let headers = sorted.filter(function (el) {
          return el != undefined
        })
        return headers
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.cell-actions
  width: 80px
</style>
